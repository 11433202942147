/* eslint-disable max-len */
import _ from 'lodash'
import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'react-i18next'
import './products-styles.scss'
import {
  CardProduct,
  FilterButtons,
  Cta,
  Spinner,
  SelectComponent,
  Seo,
} from '../../components'
import { getUrlMedia } from '../../helpers'

const ProductsView = ({
  data,
  loading,
  onHandleFilter,
  listProducts,
  category,
}) => {
  const breakpoints = useBreakpoint()
  const { t } = useTranslation()
  const objFilter = _.map(data.subcategory, (subcategory, idx) => {
    if (idx === 0) {
      return {
        id: idx,
        name: subcategory.title,
        label: subcategory.title,
        value: subcategory.title,
        slug: subcategory.title,
        activeClass: 'filter-active',
        translateValue: '0',
      }
    }
    return {
      id: idx,
      name: subcategory.title,
      slug: subcategory.title,
      label: subcategory.title,
      value: subcategory.title,
      activeClass: '',
      translateValue: `${195 * idx}px`,
    }
  })
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className="products__container container-xxl">
      <Seo title={`${t('seo.title')}: Products`} />
      <div className="products__header row">
        <h1 className="page-title col-12">{_.get(data, 'title')}</h1>
        <p className="col-12 col-sm-8">{_.get(data, 'description')}</p>
      </div>
      <div className="products__box">
        {data.subcategory
          && (breakpoints.xs ? (
            <div className="products__filter">
              <SelectComponent
                blue={true}
                placeholder={'Select Category'}
                options={objFilter}
                onChange={(e) => onHandleFilter(e.value)}
              />
            </div>
          ) : (
            <div className="products__filter">
              <FilterButtons
                data={objFilter}
                onHandleFilter={onHandleFilter}
                width={195}
              />
            </div>
          ))}
        <div className="products__cards row">
          {listProducts
            && _.map(listProducts, (item) => (
              <div className="col-12 col-sm-4 g-5 d-flex">
                <CardProduct
                  key={item.title}
                  slug={item.slug}
                  promotion="promotion"
                  name={_.get(item, 'title')}
                  category={category}
                  highlight={_.get(item, 'highlights')}
                  brief={_.get(item, 'brief')}
                  urlBackground={getUrlMedia(_.get(item, 'featured_image'))}
                  outline
                />
              </div>
            ))}
        </div>
      </div>
      <div className="products__cta">
        <Cta withAppend={true} />
      </div>
    </div>
  )
}

export default ProductsView
